import React from 'react';

export function TopNav() {
  return (
    <div>
      <div className="nav-top-container">
        <nav className="nav-top">
          <div className="tab-links">
            <h4 className="tab--notselected">
              <a href="https://digilent.com/" target="_blank">
                SHOP
              </a>
            </h4>
            <h4 className="tab--selected">
              <a href="#">PROJECTS</a>
            </h4>
            <h4 className="tab--notselected">
              <a href="https://digilent.com/reference/" target="_blank">
                {' '}
                REFERENCE
              </a>
            </h4>
          </div>
        </nav>
        <div className="nav--smalltext hide-text"><img src={process.env.PUBLIC_URL + '/images/2024-Digilent-EmersonTagline-247x24.png'} alt="Digilent is now part of Emerson" /></div>
      </div>
      <div className="div-logo">
        <img className="logo" src={process.env.PUBLIC_URL + '/images/2024-Digilent-ProjectsHeader-288x64.png'} />
      </div>
      <div className="ft-container">
        <div className="social-links">
          <div className="social-spacing">
            <div className="hide-text">|</div>
            <div>
              <a href="https://digilent.com/blog/" target="_blank">
                Blog
              </a>
            </div>
            <div>
              <a href="https://forum.digilent.com/" target="_blank">
                Forum
              </a>
            </div>
            <ul>
              <a href="https://github.com/digilent" target="_blank">
                <i className="fab fa-github icon-font"></i>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div className="conatainer" id="sitewide-banner-slot">
        <script src="https://digilent.s3.us-west-2.amazonaws.com/resources/cdn/sitewide-banner/src/sitewide-banner.js"></script>
        <link
          href="https://digilent.s3.us-west-2.amazonaws.com/resources/cdn/sitewide-banner/src/sitewide-banner.css"
          rel="stylesheet"
        />
      </div>
    </div>
  );
}
