import React, { useState } from 'react';
import axios from '../apis/axios';
import useAxios from '../hooks/useAxios';
import PageLoading from './PageLoading';
import { TopNav } from './TopNav';
import NavFilters from './NavFilters';
import { Footer } from './Footer';
import MainContent from './MainContent';
import Pagination from './Paging';
import Spinner from './Spinner';

export const HomePage = () => {
  const [params, setParams] = useState('?sortby=trending');
  const [currentPage, setCurrentPage] = useState(1);
  const [project, error, loading, filter, setFilter, spinner, setSpinner] = useAxios({
    axiosInstance: axios,
    method: 'GET',
    url: `/${params}`,
    requestConfig: {
      headers: {
        'Content-Language': 'en-US',
      },
    },
  });

  return (
    <div>
      <TopNav />
      {loading && <PageLoading />}
      {!loading && error && <div className="errMsg">{error}</div>}
      {!loading && !error && (
        <NavFilters
          params={params}
          setParams={setParams}
          setFilter={setFilter}
          filter={filter}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setSpinner={setSpinner}
        />
      )}
      {!loading && !error && spinner && <Spinner />}
      {!loading && !error && <MainContent {...project} setSpinner={setSpinner} />}
      {!loading && !error && project.records.length > 0 && project.metadata.total_pages > 1 && (
        <Pagination
          {...project}
          setFilter={setFilter}
          filter={filter}
          params={params}
          setParams={setParams}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setSpinner={setSpinner}
        />
      )}
      <Footer />
    </div>
  );
};
