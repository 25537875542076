import { useState, useEffect } from 'react';

const useAxios = (configObj) => {
  const { axiosInstance, method, url, requestConfig = {} } = configObj;
  const [response, setResponse] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const res = await axiosInstance[method.toLowerCase()](url, {
          ...requestConfig,
          signal: controller.signal,
        });
        //console.log('useEffect called');
        setResponse(res.data);
      } catch (err) {
        console.log(err.message);
        setError(err.message);
      } finally {
        setLoading(false);
        setSpinner(false);
      }
    };

    fetchData();
    // useEffect clean up function
    return () => controller.abort;
    // estint-disable-next-line
  }, [filter]);

  return [response, error, loading, filter, setFilter, spinner, setSpinner];
};

export default useAxios;
