import React from 'react';
import useScript from '../hooks/useScripts';

export function Footer() {
  return (
    <footer>
      <div className="ft-container">
        {/* Footer main */}
        <section className="ft-main">
          <div className="ft-main-item">
            <h4 className="ft-title">Company</h4>
            <ul>
              <li>
                <a href="https://digilent.com/shop/company/#about-digilent">About Us</a>
              </li>
              <li>
                <a href="https://digilent.com/shop/company/#faqs">FAQs</a>
              </li>
              <li>
                <a href="https://digilent.com/shop/distributors/">Distributors</a>
              </li>
              <li>
                <a href="https://digilent.com/shop/shipping-returns/">Shipping & Returns</a>
              </li>
            </ul>
          </div>
          <div className="ft-main-item">
            <h4 className="ft-title">News</h4>
            <ul>
              <li>
                <a href="https://digilent.com/blog/">Blog</a>
              </li>
              <li>
                <a href="https://digilent.com/shop/news/#newsletter">Newsletter</a>
              </li>
              <li>
                <a href="https://digilent.com/shop/news/#events">Events</a>
              </li>
            </ul>
          </div>
          <div className="ft-main-item footer-4">
            <h4 className="ft-title">Subscribe to our newsletter</h4>
            <p>Get the latest updates on new products and upcoming sales</p>
            <div className="_form_9"></div>
            {useScript("https://digilent.activehosted.com/f/embed.php?id=9")}
          </div>
          <div className="ft-main-item">
            <h4 className="ft-title">Contact Us</h4>
            <ul>
              <li>
                <a href="https://digilent.com/shop/support/#channels">Support Channels</a>
              </li>
            </ul>
            <p className="footer-contact">
              Digilent
              <br />
              1300 NE Henley Ct. Suite 3<br />
              Pulllman, WA 99163
              <br />
              United States of America
            </p>
          </div>
        </section>
        {/* Footer social */}
        <section className="ft-social">
          <p className="ft-copyright">
            <small>&copy; {new Date().getFullYear()} Digilent</small>
          </p>
          <ul className="ft-social-list">
            <li>
              <a href="https://twitter.com/DigilentInc/">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://facebook.com/Digilent/">
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/user/DigilentInc">
                <i className="fab fa-youtube"></i>
              </a>
            </li>
            <li>
              <a href="https://github.com/digilent">
                <i className="fab fa-github"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/digilentinc/">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/digilent-inc-/">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a href="https://www.flickr.com/photos/127815101@N07">
                <i className="fab fa-flickr"></i>
              </a>
            </li>
          </ul>
        </section>
      </div>
    </footer>
  );
}
