import axios from 'axios';

//const BASE_URL = 'https://s7ib42qkzg.execute-api.us-west-2.amazonaws.com/staging/'
const BASE_URL = 'https://syhdy3blw3.execute-api.us-west-2.amazonaws.com/prod';

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
