import React from 'react';

export default function TeamMembers(props) {
  var count = 1;
  const teamMembers =
    props.members &&
    props.members.map((author) => {
      // create comma separeted list
      var authorName = '';
      if (props.members.length > 1 && count < 2) {
        authorName = `${author.name}, `;
      } else {
        if (props.members.length > 2 && count > 2) {
          authorName = ' .';
        } else authorName = author.name;
      }
      count = ++count;

      return (
        <a href={author.url} className="authors--link" key={author.name} target="_blank">
          {authorName}
        </a>
      );
    });

  return <div className="projcard--auth">{teamMembers}</div>;
}
