import React from 'react';
import TeamMembers from './TeamMembers';

export default function ProjectCard(props) {
  //console.log(props)
  var authors = [];
  authors = props.team;

  function kFormatter(num) {
    if (num > 999999) {
      return Math.sign(num) * (Math.abs(num) / 100000).toFixed(1) + 'M';
    } else {
      if (num > 999) {
        return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K';
      } else {
        return Math.sign(num) * Math.abs(num);
      }
    }
  }

  var views = kFormatter(props.stats.views);

  return (
    <div className="projcard">
      <a href={props.url} className="projcard--image--projlink" target="_blank">
        <div className="projcard--image--container">
          <img src={`${props.cover_image_url}`} className="projcard--image" />
          <p className="projcard--img--description">{props.one_liner}</p>
        </div>
      </a>
      <div className="projcard--info">
        <div className="projcard--desc">
          <a href={props.url} target="_blank">
            <h4>{props.name}</h4>
          </a>
          <TeamMembers
            // key={authors.name}
            {...authors}
          />
        </div>
        <div className="projcard--stats">
          <div className="projcard--view">
            <img src={process.env.PUBLIC_URL + '/images/View.png'} className="projcard--view--img"></img>
            {views}
          </div>
          <div className="projcard--respects">
            <img className="img--respects" src={process.env.PUBLIC_URL + '/images/thumbsUp-2.png'}></img>
            {props.stats.respects}
          </div>
        </div>
      </div>
    </div>
  );
}
