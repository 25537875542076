import React from 'react';

export default function Pagination(props) {
  const metadata = props.metadata;
  var pageLimit = 5;
  const [pages, setPages] = React.useState(metadata.total_pages);

  //console.log(`metadata: ${props.metadata}`)
  console.log(props.params);

  // check if total_page is less than pageLimit
  // and reset if necessary
  if (metadata.total_pages < pageLimit) {
    pageLimit = metadata.total_pages;
  }

  // update params string based on selected options
  function updateParamString(name, value) {
    props.setSpinner(true);
    var urlQuery = new URLSearchParams(props.params);
    urlQuery.set(name, value);
    return `?${urlQuery}`;
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    props.setCurrentPage(pageNumber);
    props.setParams(updateParamString('page', pageNumber));
    if (pageLimit < 5) {
      setPages(pageLimit);
    }
    // toggle useEffect dependency to trigger re-render of projects
    props.setFilter(!props.filter);
    scrollTop();
  }

  function scrollTop() {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
  }

  function goToNextPage() {
    props.setCurrentPage((page) => page + 1);
    props.setParams(updateParamString('page', props.currentPage + 1));
    // toggle useEffect dependency to trigger re-render of projects
    props.setFilter(!props.filter);
    scrollTop();
  }

  function goToPrevPage() {
    props.setCurrentPage((page) => page - 1);
    props.setParams(updateParamString('page', props.currentPage - 1));
    // toggle useEffect dependency to trigger re-render of projects
    props.setFilter(!props.filter);
    scrollTop();
  }

  const getPaginationGroup = () => {
    let start = Math.floor((props.currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <div className="pagination">
      {/* previous button */}
      <button onClick={goToPrevPage} className={`prev ${props.currentPage === 1 ? 'disabled' : ''}`}>
        Prev
      </button>

      {/* show page numbers */}
      {getPaginationGroup().map((item, index) => (
        <button
          key={index}
          onClick={changePage}
          className={`paginationItem ${props.currentPage === item ? 'active' : null} `}
        >
          <span>{item}</span>
        </button>
      ))}

      {/* next button */}
      <button onClick={goToNextPage} className={`next ${props.currentPage === pages ? 'disabled' : ''}`}>
        next
      </button>
    </div>
  );
}
