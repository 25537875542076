import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

export default function PageLoading() {
  return (
    <div className="page--loading">
      <p>Loading projects....</p>
      <BeatLoader color={'#62a744'} />
    </div>
  );
}
