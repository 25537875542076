import React, { useEffect } from 'react';
import ProjectCard from './ProjectCard';

const MainContent = (props) => {
  const digilentProjects =
    props.records &&
    props.records.map((project) => {
      return <ProjectCard key={project.hid} {...project} />;
    });

  return (
    <div className="main-content">
      {digilentProjects.length > 0 ? digilentProjects : <p className="no--projects">No Projects Found.</p>}
    </div>
  );
};

export default MainContent;
