import React from 'react';

export default function NavFilters(props) {
  const [filterItems, setFilterItems] = React.useState({
    type: '',
    difficulty: '',
    sort: '',
  });

  // update params string based on selected options
  function updateParamString(name, value) {
    var urlQuery = new URLSearchParams(props.params);
    urlQuery.set(name, value);
    urlQuery.set('page', 1); //reset page to 1
    return `?${urlQuery}`;
  }

  // set filter based on selected options
  function handleChange(event) {
    props.setSpinner(true);
    const { name, value } = event.target;
    setFilterItems((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });

    // update parameters for api call
    props.setParams(updateParamString(name, value));
    // toggle useEffect dependency to trigger re-render of projects
    props.setFilter(!props.filter);
    // rest pagination, current page to 1
    props.setCurrentPage(1);
  }

  return (
    <div>
      <form className="nav-filter">
        <div className="nav-filter-select">
          <div className="select-wrapper">
            <select name="type" id="type" value={filterItems.type} onChange={handleChange}>
              <option value="" className="select-header">
                Show all categories
              </option>
              <option value="tutorial">Tutorial</option>
              <option value="wip">Work in Progress</option>
              <option value="getting_started">Getting Started</option>
              <option value="protip">Pro Tip</option>
              <option value="showcase">Showcase</option>
              <option value="teardown">Tear Down</option>
            </select>
          </div>
          <div className="select-wrapper">
            <select name="difficulty" id="difficulty" value={filterItems.difficulty} onChange={handleChange}>
              <option value="">Show all difficulties</option>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
              <option value="hardcore">Hardcore</option>
            </select>
          </div>
          <div className="select-wrapper">
            <select name="sort" id="sort" value={filterItems.sort} onChange={handleChange}>
              <option value="trending">Sort by Trending</option>
              <option value="popular">Sort by Popular</option>
              <option value="recent">Sort by Recent</option>
              <option value="respected">Sort by Respected</option>
              <option value="updated">Sort by Updated</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  );
}
