import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

export default function Spinner() {
  return (
    <div className="spinner">
      <BeatLoader color={'#62a744'}></BeatLoader>
    </div>
  );
}
